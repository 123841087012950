<template>
  <tr>
    <td class="text-center">
      {{ item.id }}
    </td>
    <td class="text-center">
      {{ item.user.name }}
    </td>
    <td class="text-center">
      {{ item.user.email }}
    </td>
    <td class="text-center">
      {{ item.user.phone_number }}
    </td>
    <td class="text-center">
      {{ item.product.name }}
    </td>
    <td class="text-center">
      {{ item.qty }}
    </td>
    <td class="text-center">
      {{ item.created_at }}
    </td>
  </tr>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'BuyRequestItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      maxGrowingAgeLine () {
        return this.item.max_growing_age ? this.item.max_growing_age : 'Not Set'
      },
      isActive () {
        return this.item.is_active ? 'Yes' : 'No'
      },
    },
    methods: {
      ...mapActions({
        approve: 'auction/buyRequest/approve',
        decline: 'auction/buyRequest/decline',
      }),
      approveBuyRequest () {
        this.approve({
          buy_request_id: this.item.id,
        })
      },
      declineBuyRequest () {
        this.decline({
          buy_request_id: this.item.id,
        })
      },
    },
  }
</script>
