<template>
  <v-dialog
    v-model="opened"
    persistent
    max-width="600px"
  >
    <material-card
      dark
      title="Buy Request Approval"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="qty_for_purchase"
                type="number"
                color="purple"
                label="Qty for Purchase"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="initial_sale_id"
                :items="initialSales"
                item-text="name"
                item-value="id"
                label="Initial Sale"
                persistent-hint
                single-line
                :loading="initialSalesLoading"
              />
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="landing_date_menu"
                v-model="landing_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="landing_date"
                    color="purple"
                    label="Landing Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="landing_date"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="red darken-1"
          text
          @click="declineBuyRequest"
        >
          Decline
        </v-btn>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="approveBuyRequest"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </material-card>
  </v-dialog>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'ViewItemModal',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        opened: false,
        qty_for_purchase: this.item.qty,
        initial_sale_id: null,
        landing_date: null,
        landing_date_menu: false,
        initialSales: [],
        initialSalesLoading: false,
      }
    },
    computed: {
      ...mapGetters({
        token: 'admin/getAccessToken',
      }),
    },

    watch: {
      'item.id': function () {
        if (this.item.id) {
          this.qty_for_purchase = this.item.qty
          this.initialSalesLoading = true
          this.loadProductInitialSales({
            product_id: this.item.product_id,
            qty: this.qty_for_purchase,
          })
            .then(result => {
              this.initialSales.push({
                id: null,
                name: 'Create new with exact QTY',
              })
              result.data.forEach(item => {
                this.initialSales.push({
                  id: item.id,
                  name: `${this.item.product.name}, ${item.available_qty}pcs.`,
                })
              })
            })
            .finally(() => {
              this.initialSalesLoading = false
            })
        } else {
          this.qty_for_purchase = 0
          this.initialSales = []
        }
      },
    },
    methods: {
      ...mapActions({
        approve: 'auction/buyRequest/approve',
        decline: 'auction/buyRequest/decline',
        loadProductInitialSales: 'nomenclature/loadProductInitialSales',
      }),
      approveBuyRequest () {
        this.approve({
          buy_request_id: this.item.id,
          landing_date: this.landing_date,
          initial_sale_id: this.initial_sale_id,
          qty_for_purchase: this.qty_for_purchase,
        })
          .then(result => {
            if (result && result.status === 'success') {
              this.$emit('approved', this.item.id)
              this.closeDialog()
            }
          })
      },
      declineBuyRequest () {
        this.decline({
          buy_request_id: this.item.id,
        })
          .then(result => {
            if (result && result.status === 'success') {
              this.$emit('declined', this.item.id)
              this.closeDialog()
            }
          })
      },
      openDialog () {
        this.qty_for_purchase = this.item.qty
        this.opened = true
      },
      closeDialog () {
        this.opened = false
        this.qty_for_purchase = 0
      },
    },
  }
</script>
