<template>
  <v-container
    id="nomenclature-view"
    fluid
    tag="section"
  >
    <material-card
      dark
      icon="mdi-clipboard-text"
      icon-small
      title="Buy Requests"
      color="accent"
    >
      <v-data-table
        :headers="headers"
        :loading="loading"
        :items="buyRequests"
      >
        <template v-slot:body="{ items }">
          <list-item
            @click.native="openViewModal(item)"
            v-for="item in items"
            :key="item.id"
            :item="item"
          />
        </template>
      </v-data-table>
    </material-card>
    <view-item-modal
      ref="viewItemModal"
      :item="itemToEdit"
      @approved="itemApproved"
      @declined="itemDeclined"
    />
  </v-container>
</template>
<script>
  import { mapActions } from 'vuex'
  import ListItem from '@/components/Auction/BuyRequest/ListItem'
  import ViewItemModal from '@/components/Auction/BuyRequest/ViewItemModal'

  export default {
    name: 'BuyRequests',
    components: {
      ListItem,
      ViewItemModal,
    },
    data: () => ({
      loading: false,
      headers: [
        { text: 'ID', align: 'center', width: '10%', value: 'id' },
        { text: 'Customer Name', align: 'center', width: '20%', value: 'user.name' },
        { text: 'Email', align: 'center', width: '20%', value: 'user.email' },
        { text: 'Phone Number', align: 'center', width: '20%', value: 'user.phone_number' },
        { text: 'Plant', align: 'center', width: '15%', value: 'product.name' },
        { text: 'Qty', align: 'center', width: '10%', value: 'qty' },
        { text: 'Placed At', align: 'center', width: '20%', value: 'created_at' },
      ],
      buyRequests: [],
      itemToEdit: {},
    }),
    mounted () {
      this.loading = true
      this.loadBuyRequests()
        .then(result => {
          this.buyRequests = result.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    methods: {
      ...mapActions({
        loadBuyRequests: 'auction/buyRequest/list',
      }),
      openViewModal (item) {
        this.itemToEdit = item
        this.$refs.viewItemModal.openDialog()
      },
      itemApproved (itemId) {
        this.buyRequests.splice(this.buyRequests.findIndex(i => i.id === itemId), 1)
      },
      itemDeclined (itemId) {
        this.buyRequests.splice(this.buyRequests.findIndex(i => i.id === itemId), 1)
      },
    },
  }
</script>
